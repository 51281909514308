import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance: AxiosInstance = axios.create({
    baseURL,
    timeout: 100000,
});

export const setJwtToken = (token: string | null) => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export const setInterceptor = () => {
    axiosInstance.interceptors.response.use(
        (res: AxiosResponse<any>) => {
            return res;
        },
        (err: AxiosError<any>) => {
            const error = err.response;
            let errorMsg: string | undefined;

            if (error && error.status === 401) {
                errorMsg = `Your previous login session has expired. Please login again to use the platform.`;
            } else if (error && error.status === 403) {
                errorMsg = `${error.status}`;
            } else if (error && error.status === 500) {
                errorMsg = error.data?.message || 'Internal Server Error';
            }

            return Promise.reject(errorMsg || `Sorry, some system issue. Please try again and if the issue still persists, please report it to our team at publir`);
        },
    );
};

export default axiosInstance;
